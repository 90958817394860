import React from 'react';
import Image from "../Images/Image"
import { Remarkable } from 'remarkable';

const Header = ({ title, description, picture, headerColor, button }) => {
    var md = new Remarkable();
    return (
        <section class="hero is-medium" style={{ backgroundColor: headerColor }}>
            <div class="hero-body">
                <div class="container">
                    <div className="columns is-centered is-vcentered">
                        <div className="column is-4">
                            <div
                                style={{ fontSize: "25px", paddingBottom: "15px", fontWeight: "bold" }}
                                dangerouslySetInnerHTML={{ __html: md.render(title) }}
                            />
                            <div
                                style={{ paddingBottom: "15px" }}
                                className="pt-5"
                                dangerouslySetInnerHTML={{ __html: md.render(description) }}
                            />
                            {button}
                        </div>
                        <div className="column is-4">
                            <Image src={picture} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;