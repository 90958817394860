import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionText from "../components/SectionText/SectionText"
import Header from "../components/Courses/Header"


const thankYouPage = ({data}) => {
    const frontmatter = data.markdownRemark.frontmatter
    const { title, description, headerColor, link } = frontmatter.remerciement

    return (
        <Layout>
            <SEO title="Merci" />
            <Header
                title={title}
                picture={frontmatter.image}
                description={description}
                headerColor={headerColor}
                button={<a class="button" href={link}>Lien vers la formation</a>}
            />
        </Layout>
    );
};

export default thankYouPage;

export const ThankYouQuery = graphql`
  query ThankYouPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        frontmatter {
            title
            shortTitle
            remerciement {
                description
                headerColor
                link
                title
            }
            price
            templateKey
            slug
            image
        }
        html
    }
    allFile( filter: { internal: { mediaType: { regex: "images/" } } } ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
    }
  }
`